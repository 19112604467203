<template>
  <div class="nk-app-root">
    <!-- wrap @s -->
    <div class="nk-wrap">
      <!-- Vue Header -->
      <Header></Header>
      <!-- Vue Content -->
      <slot></slot>
      <!-- Vue Footer -->
      <Footer></Footer>
    </div>
    <!-- wrap @e -->
  </div>
  <!-- app-root @e -->
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
import {defineComponent, onMounted} from 'vue';
export default defineComponent({
  name: "AppView",
  components: {Footer, Header},
  setup(){
    onMounted(() => {
      window.NioApp.init();
    });
  }
});
</script>

<style scoped>

</style>
<template>
  <!-- main header @s -->
  <div class="nk-header nk-header-fluid is-theme">
    <div class="container-xl wide-xl">
      <div class="nk-header-wrap">
        <div class="nk-menu-trigger mr-sm-2 d-lg-none">
          <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em class="icon ni ni-menu"></em></a>
        </div>
        <div class="nk-header-brand">
          <router-link to="/" class="logo-link">
            <img class="logo" src="@/assets/images/logoblanco.png" alt="Sombreros Charros">
          </router-link>
        </div><!-- .nk-header-brand -->
        <div class="nk-header-menu" data-content="headerNav">
          <div class="nk-header-mobile">
            <div class="nk-header-brand">
              <router-link to="/" class="logo-link">
                <img class="logo" src="@/assets/images/logocolor.png" alt="Sombreros Charros">
              </router-link>
            </div>
            <div class="nk-menu-trigger mr-n2">
              <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em class="icon ni ni-arrow-left"></em></a>
            </div>
          </div>
          <Menu></Menu>
        </div><!-- .nk-header-menu -->
        <div class="nk-header-tools">
          <ul class="nk-quick-nav">
            <li class="dropdown user-dropdown order-sm-first">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <div class="user-toggle">
                  <div class="user-avatar">
                    <span>{{$filters.initials(user)}}</span>
                  </div>
                  <div class="user-info d-none d-xl-block">
                    <div class="user-status text-warning text-capitalize">{{user?.role}}</div>
                    <div class="user-name dropdown-indicator">{{user?.name}} {{user?.lastname}}</div>
                  </div>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                  <div class="user-card">
                    <div class="user-avatar">
                      <span>{{$filters.initials(user)}}</span>
                    </div>
                    <div class="user-info">
                      <span class="lead-text">{{user?.name}} {{user?.lastname}}</span>
                      <span class="sub-text">{{user?.email}}</span>
                    </div>
                  </div>
                </div>
                <div class="dropdown-inner">
                  <ul class="link-list">
                    <li><router-link :to="{name: 'Perfil'}"><em class="icon ni ni-user-alt"></em><span>Editar Cuenta</span></router-link></li>
                  </ul>
                </div>
                <div class="dropdown-inner">
                  <ul class="link-list">
                    <li><a href="#" class="text-danger" @click.prevent="confirmLogout"><em class="icon ni ni-signout"></em><span>Cerrar Sesión</span></a></li>
                  </ul>
                </div>
              </div>
            </li><!-- .dropdown -->
          </ul><!-- .nk-quick-nav -->
        </div><!-- .nk-header-tools -->
      </div><!-- .nk-header-wrap -->
    </div><!-- .container-fliud -->
  </div>
  <!-- main header @e -->
</template>

<script>
import Menu from "./Menu";
import Swal from "sweetalert2";
import {useRouter} from 'vue-router';
import useAuth from '@/providers/auth';
import {watch} from "vue";

export default {
  name: "Header",
  components: {
    Menu
  },
  setup(){
    const { user, logout } = useAuth();

    const router = useRouter();

    function confirmLogout(){
      Swal.fire({
        title: 'Cerrar sesión',
        text: '¿Estás seguro que deseas cerrar sesión?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(res => {
        if(res.isConfirmed){
          logout();
        }
      });
    }

    watch(user, () => {
      if(!user.value) {
        router.replace({name: 'Login'});
      }
    });

    return { user, logout, confirmLogout };
  }
}
</script>

<style scoped>
.logo {
  height: 40px;
}
</style>
<template>
    <!-- footer @s -->
    <div class="nk-footer nk-footer-fluid bg-lighter">
        <div class="container-xl wide-lg">
            <div class="nk-footer-wrap">
                <div class="col-12 text-center">
                    © {{year}} | Hecho con <i class="ni ni-coffee-fill"></i> y <i class="ni ni-heart-fill text-danger"></i> en <a href="https://tekoestudio.com" class="text-info" target="_blank">Teko Estudio</a>
                </div>
            </div>
        </div>
    </div>
    <!-- footer @e -->
</template>

<script>
    import { ref } from 'vue';
    export default {
        name: "Footer",
        setup() {
            const year = ref(new Date().getFullYear());
            return { year };
        }
    }
</script>

<style scoped>

</style>